<template>
  <!--  <nav-bar/>-->
  <div class="body">
    <img src="../assets/lt.png" style="height: 100%;width: 100%;" alt=""/>
    <div class="detail">
      <div style="height: 10rem">
        <div class="detail_fixed">
          <div class="input">
            <input @focus="focus" v-model="input" placeholder="请输入您的手机号">
          </div>
          <div class="btn">
            <div class="text" @click="btnClick">立即使用</div>
          </div>
        </div>
      </div>

      <div class="checkbox">
        <input type="checkbox" v-model="option" name="option" value="value">
        <div>
          <span>我已经阅读并同意</span>
<!--          <div class="span" @click="privacy">活动规则</div>-->
<!--          <span>、</span>-->
          <div class="span" @click="privacy">隐私条款</div>
          <span>等协议</span>
        </div>
      </div>
      <div class="checkbox_detail">
        <div>业务名称：影音娱乐铂金会员</div>
        <div>业务资费：29.9元/30天</div>
        <div>客服电话：4008885790</div>
      </div>

    </div>
    <div class="vant">
      <van-dialog v-model:show="privacyVisible" show-cancel-button @confirm="confirm">
        <div class="panel">
          <Privacy/>
        </div>
      </van-dialog>
      <van-dialog v-model:show="serviceVisible" show-cancel-button @confirm="confirm">
        <div class="panel">
          <Service/>
        </div>
      </van-dialog>
    </div>
  </div>
</template>

<script>

import {Toast} from 'vant';
import {push, subscribe, subscribeFail} from "@/api";
import Privacy from "@/views/privacy.vue";
import Service from "@/views/service.vue";
import {px2Rem, px2Rem1} from "@/utils/rem";
import {decode} from "js-base64";

export default {
  name: "index",
  components: {Service, Privacy},
  data() {
    return {
      input: "",
      option: false,
      privacyVisible: false,
      serviceVisible: false,
      iframeVisible: false,
      query: {},
    }
  },
  created() {
    push({
      type: 4,
    })


  },
  mounted() {
    this.query = this.$route.query
    if (this.$route.query?.phone) {

      let regex = /^1[3456789]\d{9}$/;

      if (regex.test(this.query.phone)) {
        this.input = this.query.phone
      } else {
        this.query.phone = ""
      }
    }
    window.addEventListener('scroll', function () {
      let scrollDistance = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
      if (scrollDistance > 670) {
        let dom = document.getElementsByClassName("detail_fixed")
        dom[0].style.position = "fixed"
      } else {
        let dom = document.getElementsByClassName("detail_fixed")
        dom[0].style.position = "static"
      }
    });
  },
  methods: {
    btnClick() {
      this.option = true
      push({
        type: 5,
      })
      if (this.option) {
        let regex = /^1[3456789]\d{9}$/;

        // 测试输入的手机号是否符合格式
        if (regex.test(this.input)) {
          let toast = Toast.loading({
            duration: 0, // 持续展示 toast
            message: '加载中...',
            forbidClick: true,
          });
          let data = {
            userCode: this.input,
            channelId: this.query.channelId ? this.query.channelId : null,
          }
          subscribeFail({
            ...data
          }).then(res => {
            if (res.data.success) {
              toast.clear()
              window.location.href = res.data.data
            } else {
              Toast("订购失败请重试！")
            }
          }).catch(e => {
            Toast("订购失败请重试1！")
          })
        } else {
          Toast('手机号格式错误')
        }
      } else {
        Toast('请阅读并同意《用户隐私政策》')
      }
    },
    privacy() {
      this.privacyVisible = true
    },
    service() {
      this.serviceVisible = true
    },
    confirm() {
      this.option = true
    },
    focus() {
      push({
        type: 6,
      })
    },
  }
};
</script>

<style lang="less">
body {
  margin: 0;
  padding: 0;

  .van-toast {
    width: 12rem;
    text-align: center;
    //background-color: #01d0c0;
  }

  .van-popup--center {
    top: 40%;
  }

  .van-toast--text {
    font-size: .8rem;
  }

  .panel {
    height: 500px;
    overflow-y: auto;

  }

  .myIframePaneldialog {
    .van-dialog__footer {
      display: none;
    }
  }

  .myIframePanel {
    height: 16rem;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;


    .myIframePanel1 {
      height: 800px;
      width: 100%;
    }
  }

  .myIframePanel::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  #uniauthframe {
    body {
      overflow: hidden;
    }
  }

  .van-dialog {
    width: 20rem;
  }

  .van-dialog__cancel {
    height: 2rem;
  }

  .van-dialog__confirm {
    height: 2rem;
  }

  .vant {
    .van-popup--center {
      top: 50%;
    }
  }
}

@keyFrames mymove {
  0% {
    transform: scale(.9, .9);
  }
  25% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(.9, .9);
  }
}

@-webkit-keyframes mymove {
  0% {
    transform: scale(.9, .9);
  }
  25% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(.9, .9);
  }
}

.body {
  margin: 0 auto;
  padding: 0;
  position: relative;
  max-width: 750px;
  //padding: 0 1rem
  .detail {
    width: 100%;
    position: absolute;
    top: 31rem;
    text-align: center;
  }

  .input {
    width: 17rem;
    height: 3.2rem;
    margin: 0 auto;

    input {
      border: none;
      border-radius: 1.87rem;
      padding: .75rem 2rem;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      text-align: center;
    }

    input::placeholder {
      text-align: center;
      font-size: 1.2rem;
    }
  }

  .btn {

    width: 13.05rem;
    //height: 2.45rem;
    margin: 1.5rem auto 0;
    line-height: 2.45rem;
    text-align: center;
    position: relative;
    z-index: 1;
    //animation: mymove .7s infinite;
    //-webkit-animation: mymove .7s infinite;

    .text {

      font-size: 1.6rem;
      //font-family: Adobe Heiti Std;
      height: 2.9rem;
      line-height: 3rem;
      font-weight: 600;
      color: #E71C1C;
      border-radius: 3rem;
      background: #fff500;
      box-shadow: 0 4px 1px 0 rgba(212, 205, 27, 1);

    }

    .position {
      position: absolute;
      right: 0;
      top: -1rem;
      width: 4.5rem;
      height: 1.63rem;
      z-index: -1;
      line-height: 1.1rem;
      box-sizing: border-box;
      background: linear-gradient(-90deg, #25A0FF, #01D0C0);
      font-size: 0.81rem;
      text-align: center;
      font-weight: normal;
      color: #FFFFFF;
      clip-path: polygon(0% 0%, 100% 0%, 100% 60%, 0% 200%);
    }

  }

  .checkbox_detail {

    font-size: 0.7rem;
    color: rgba(255, 255, 255, 1);

    div {
      height: 1.3rem;
      line-height: 1.3rem;
    }
  }

  .checkbox {
    //margin-top: 1.8rem;
    height: 1rem;
    line-height: 1rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      display: inline-block;
      width: 1rem;
      height: 0.8rem;
      //width: 0.69rem;
      //height: 0.69rem;

      //border: 1px solid #E54243;
    }

    div {
      //width: 5.91rem;
      //height: 1rem;
      line-height: 1rem;
      font-size: 0.7rem;
      margin-left: 2px;
      display: inline-block;
      color: rgba(255, 255, 255, 1);

      .span {
        color: #4e98f6;
        display: inline-block;
      }
    }
  }

  #container {
    width: 100%;
    height: 4rem;
    //background-color: black;
    overflow: hidden;
    position: relative;
    margin-bottom: 1.63rem;
  }

  .danMu {
    position: absolute;
    top: 2.38rem;
    right: 0;
    white-space: nowrap;
    color: #fff;
    padding: 0 15px;
    background: #FBF3DA;
    border-radius: 0.72rem;
    height: 1.44rem;
    line-height: 1.44rem;
    font-size: 0.69rem;
    font-weight: 400;
    color: rgba(52, 10, 0, 0.6);
  }

  .danMu111 {
    transform: translateX(100%);
    transition: transform 5s ease-in-out;
  }

  .danMu111 {
    transform: translateX(0);
  }

  @keyframes move {
    from {
      right: -10rem;
    }
    to {
      right: 100%;
    }
  }

  .detail_fixed {
    /* position: fixed; */
    text-align: center;
    width: 100%;
    background-color: #ff3b3a;
    height: 8rem;
    /* padding: 0.5rem; */
    bottom: 0;
    padding: 1rem 0;
    max-width: 750px;
  }


}

.amap-logo,
.amap-copyright {
  display: none !important;
}

.amap-logo {
  display: none !important;
}

.amap-copyright {
  opacity: 0;
}
</style>
