import request from "@/utils/request";

// let url = "http://api.hangzhouluofeng.com:8081"
let url = "https://api.hangzhouluofeng.com:8081"

// let url = "http://106.14.60.41:8080"

export function push(data) {
    return request({
        url: url + '/api/data/push',
        method: 'get',
        params: data
    })
}

export function subscribe(data) {
    return request({
        url: url + '/api/user/subscribe',
        method: 'post',
        data: data
    })
}

export function subscribeFail(data) {
    return request({
        url: url + '/api/user/subscribe_fail',
        method: 'post',
        data: data
    })
}
